.text-overflow {
  display: block;
  padding: 9.5px;
  margin-bottom: 25px;
  /* margin: 0 0 10px; */
  /* font-size: 13px; */
  line-height: 1.42857143;
  /* color: #333; */
  background-color: rgba(0, 0, 0, 0.05);
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: "scroll";
  white-space: pre-line;
}
.tab-content-border2 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.page-item.active .page-link {
  background-color: white;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.6em;
  margin-left: 2px;
  font-size: 75%;
  color: white;
  font-weight: 700;
  line-height: 1;
  background-color: #777;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.45rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.badge-div .MuiButton-root:focus,
.badge-div .MuiButton-root:active {
  outline: 0 !important;
}
.pubselect {
  margin-right: 5px;
  color: black;
}
.Glycosummary {
  padding-top: 20px !important;
  padding-bottom: 20px !important ;
  padding-left: 15px;
}
/* .Tabcss {
  padding-top: 20px;
}
.singleTabcss {
  padding-bottom: 20px;
} */
.Sorted {
  margin-right: 5px;
  color: black;
}
.badge-count {
  background-color: white;
  color: black;
  height: 27px;
  min-width: 27px;
  line-height: 27px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-align: center;
  font-size: 16px;
  margin-left: 5px;
  padding-right: 2px;
  padding-left: 2px;
}
.badge-div {
  /* margin: 0.5em 0 0; */
  /* margin-left: -20px; */
  margin-top: 5px !important;
}
.recordInfo {
  color: black;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.evidencetype {
  text-transform: capitalize;
}
.badge-div + .badge-div {
  margin: 10px 0 0 !important;
  margin-left: 2px;
}

.inline-evidence:nth-child(n + 5) {
  margin-top: 5px !important;
}

legend {
  all: unset;
}

.img-wrapper {
  overflow: hidden;
  z-index: -1000;
}
.img-cartoon {
  /* display: block; */
  width: auto;
  height: auto;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  box-shadow: 10px 10px 5px #ddd;
}
.img-cartoon:hover,
.img-cartoon:focus {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: none !important;
}

.no-data-msg {
  margin-left: 15px;
}

.no-data-msg-publication {
  margin: 20px 0 36px 20px;
}
.go-annotation-total {
  margin-left: 20px;
}

.badge-grid {
  padding-bottom: 20px;
}
@media (max-width: 480px) {
  .img-cartoon {
    width: 100% !important;
    margin: 0 auto !important;
  }
}
