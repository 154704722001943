
/* 
 * 
 */

.glymagesvg_glycanimage:hover .glymagesvg_hover_outline_red * {
    stroke: rgb(247, 71, 62) !important;
    stroke-width: 4;
}

.glymagesvg_glycanimage .glymagesvg_outline_red * {
    stroke: rgb(247, 71, 62) !important;
    stroke-width: 4;
}

.glymagesvg_glycanimage:hover .glymagesvg_hover_text_red text {
    fill: rgb(247, 71, 62) !important;
    font-weight: bold;
}

.glymagesvg_glycanimage .glymagesvg_text_red text {
    fill: rgb(247, 71, 62) !important;
    font-weight: bold;
}

.glymagesvg_checkbox {
    font-weight: bold;
    background-color: white;
    color: white;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    display: inline;
}

.glymagesvg_checkbox_red_checked {
    color: rgb(247, 71, 62);
}

.glymagesvg_checkbox_black_checked {
    color: black;
}

.glymagesvg_glycanimage:hover .glymagesvg_hover_outline_green * {
    stroke: rgb(96, 247, 62) !important;
    stroke-width: 4;
}

.glymagesvg_glycanimage .glymagesvg_outline_green * {
    stroke: rgb(96, 247, 62) !important;
    stroke-width: 4;
}

.glymagesvg_background_red {
    background-color: rgb(247, 71, 62) !important;
}

.glymagesvg_background_green {
    background-color: rgb(96, 247, 62) !important;
}

.glymagesvg_background_yellow {
    background-color: yellow !important;
}

.glymagesvg_low_opacity g g {
   opacity: 0.2;
}
.glymagesvg_glycanimage g g.glymagesvg_monomask {
   opacity: 1.0;
}
.glymagesvg_glycanimage g g.glymagesvg_high_opacity {
   opacity: 1.0;
}
.glymagesvg_glycanimage g g.glymagesvg_high_opacity_anomer {
   opacity: 1.0;
}
.glymagesvg_glycanimage g g.glymagesvg_high_opacity_anomer text:nth-last-child(n + 2) {
   opacity: 0.2;
}
.glymagesvg_hover_low_opacity:hover g g {
   opacity: 0.2;
}
.glymagesvg_glycanimage:hover g g.glymagesvg_monomask {
   opacity: 1.0;
}
.glymagesvg_glycanimage:hover g g.glymagesvg_hover_high_opacity {
   opacity: 1.0;
}
.glymagesvg_glycanimage:hover g g.glymagesvg_hover_high_opacity_anomer {
   opacity: 1.0;
}
.glymagesvg_glycanimage:hover g g.glymagesvg_hover_high_opacity_anomer text:nth-last-child(n + 2) {
   opacity: 0.2;
}

.glymagesvg_glycanimage_motifalignments g g.glymagesvg_monomask {
   opacity: 1.0;
}
.glymagesvg_glycanimage_motifalignments g g.glymagesvg_high_opacity {
   opacity: 1.0;
}
.glymagesvg_glycanimage_motifalignments g g.glymagesvg_high_opacity_anomer {
   opacity: 1.0;
}
.glymagesvg_glycanimage_motifalignments g g.glymagesvg_high_opacity_anomer text:nth-last-child(n + 2) {
   opacity: 0.2;
}

.glymagesvg_glycanimage_enzyme g g.glymagesvg_monomask {
   opacity: 1.0;
}
.glymagesvg_glycanimage_enzyme g g.glymagesvg_high_opacity {
   opacity: 1.0;
}
.glymagesvg_glycanimage_enzyme g g.glymagesvg_high_opacity_anomer {
   opacity: 0.2;
}

.glymagesvg_glycanimage_iupac g g.glymagesvg_monomask {
   opacity: 1.0;
}
.glymagesvg_glycanimage_iupac g g.glymagesvg_high_opacity {
   opacity: 1.0;
}
.glymagesvg_glycanimage_iupac g g.glymagesvg_high_opacity_anomer {
   opacity: 0.2;
}

