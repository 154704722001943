.gg-protvista-container {
  width: 95%;
}
.prot-sidebar {
  padding: 0 !important;
  margin: 0 auto !important;
}
.prot-body-content {
  margin: 30px 0;
}
.icons-content {
  margin: 0 0 10px;
}
#nglyco {
  padding-left: 20%;
}
#oglyco {
  padding-left: 20%;
}
.nav-itemss {
  height: 40px;
  background-color: #e6e6e6;
}
.nav-item1 {
  height: 40px;
  background-color: #e6e6e6;
}
.nav-track {
  height: 60px;
  border-bottom: 1px solid rgb(166, 166, 169);
}

.hidden {
  display: none !important;
}

.glycotrack {
  height: 60px;
}

/* circle */
.super1 {
  color: red;
  font-size: 20px;
  padding-top: 10px;
  cursor: pointer;
}
/* triangle */
.super2 {
  color: red;
  font-size: 22px;
  padding-top: 10px;
  cursor: pointer;
}
/* circle */
.super3 {
  color: blue;
  font-size: 20px;
  cursor: pointer;
}
/* triangle */
.super4 {
  color: blue;
  font-size: 22px;
  cursor: pointer;
}
/* dimond  */
.super5 {
  font-size: 30px;
  color: green;
  cursor: pointer;
  line-height: 20px !important;
}
.super6 {
  font-size: 26px;
  color: orange;
  cursor: pointer;
  line-height: 20px !important;
}
.superx {
  font-size: 16px;
  color: black;
  margin-left: 10px !important;
  cursor: pointer;
}
.super7 {
  font-size: 26px;
  color: purple;
  cursor: pointer;
  line-height: 20px !important;
}
/* circle */
.super8 {
  color: #8480F3;
  font-size: 20px;
  padding-top: 10px;
  cursor: pointer;
}
/* circle */
.super9 {
  color: #42C2C2;
  font-size: 20px;
  padding-top: 10px;
  cursor: pointer;
}

#glycotrack,
#glycotrack1 {
  height: 60px;
}
.sizefor {
  font-size: 24px;
}
.nav-glycosylation {
  height: 60px;
}
.nav-combinetrack {
  height: 80px;
  /* padding-top: 10px; */
}
.nav-links {
  /* padding-top: 50px; */
  margin-left: 15px !important;
}
.indentsubnav {
  padding-bottom: -10px;
  padding-left: 17px;
  background-color: rgb(252, 252, 252);
}
.legendlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none !important;
  border-bottom: 1px solid rgb(166, 166, 169);
}
.emptytrack {
  height: 80px;
}
.highlight {
  background-color: rgb(243, 240, 211);
}
.sidebarpadding {
  padding-top: 30px;
}
.main-nav-list {
  padding: 0 0 0 15px !important;
}

.tooltip-close {
  position: absolute;
  top: 5px;
  right: 5px;
}
